import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from 'components/Button';
import Container from 'components/Container';
import FormCheckbox from 'components/FormCheckbox/FormCheckbox';
import FormElement from 'components/FormElement';
import FormField from 'components/FormField';
import * as CONSTANTS from 'core/constants/config';
import useHeader from 'core/hooks/use-header';
import * as OptionTypes from 'core/models/options.model';
import * as UserTypes from 'core/models/user.model';
import { UserData } from 'core/models/user.model';
import { AppDispatch } from 'core/store/store';
import { UserSelectors } from 'core/store/user/user.selectors';
import { UserThunks } from 'core/store/user/user.thunks';
import styles from './UserQuestionnaire.module.css';

type Inputs = {
  age: number;
  oldJob: string | null;
  unemploymentDuration: number;
  researchedJob: string | null;
  gender: UserTypes.UserGender;
  dyslexia: UserTypes.UserDyslexia;
  professionalProject: string | null;
  researchedFormation: string | null;
  studiesLevel: UserTypes.UserStudiesLevel;
  familySituation: UserTypes.UserFamilySituation;
  thresholdSalary: UserTypes.UserThresholdSalary;
  meansOfTransport: UserTypes.UserMeansOfTransport[];
  financiallyCompensated: UserTypes.UserFinanciallyCompensated;
};

const UserQuestionnaire: React.FC = (): JSX.Element | null => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { search } = useLocation();
  const { setBackPath } = useHeader();
  const dispatch = useDispatch<AppDispatch>();
  const user = useSelector(UserSelectors.data);

  const { formState, handleSubmit, register } = useForm<Inputs>({
    mode: 'onChange',
    defaultValues: {
      age: user?.age,
      gender: user?.gender,
      oldJob: user?.oldJob,
      dyslexia: user?.dyslexia,
      studiesLevel: user?.studiesLevel,
      researchedJob: user?.researchedJob,
      thresholdSalary: user?.thresholdSalary,
      familySituation: user?.familySituation,
      meansOfTransport: user?.meansOfTransport,
      researchedFormation: user?.researchedFormation,
      professionalProject: user?.professionalProject,
      unemploymentDuration: user?.unemploymentDuration,
      financiallyCompensated: user?.financiallyCompensated,
    },
  });
  const { isSubmitting, errors, dirtyFields } = formState;
  const [formIsDirty, setFormIsDirty] = useState<boolean>(false);

  const dyslexiaOptions = t('user-form.fields.dyslexia.options', {
    returnObjects: true,
  }) as OptionTypes.DyslexiaOption[];
  const genderOptions = t('user-form.fields.gender.options', { returnObjects: true }) as OptionTypes.GenderOption[];
  const studiesLevelOptions = t('user-form.fields.studies-level.options', {
    returnObjects: true,
  }) as OptionTypes.StudiesLevelOption[];
  const situationOptions = t('user-form.fields.family-situation.options', {
    returnObjects: true,
  }) as OptionTypes.FamilySituationOption[];
  const thresholdSalaryOptions = t('user-form.fields.threshold-salary.options', {
    returnObjects: true,
  }) as OptionTypes.ThresholdSalaryOption[];
  const meansOfTransportOptions = t('user-form.fields.means-of-transport.options', {
    returnObjects: true,
  }) as OptionTypes.MeansOfTransportOption[];
  const financiallyCompensatedOptions = t('user-form.fields.financially-compensated.options', {
    returnObjects: true,
  }) as OptionTypes.FinanciallyCompensateOption[];

  const onSubmit = async (inputs: Inputs) => {
    try {
      const data: UserData = {
        ...inputs,
        oldJob: inputs.oldJob || undefined,
        dyslexia: inputs.dyslexia || undefined,
        researchedJob: inputs.researchedJob || undefined,
        researchedFormation: inputs.researchedFormation || undefined,
        professionalProject: inputs.professionalProject || undefined,
      };
      await dispatch(UserThunks.putMe(data));
      navigate(`/questionnaires${search}`);
    } catch (e: unknown) {
      const error = e as Error;
      toast.error(error.message);
    }
  };

  const isInvalid = (name: keyof Inputs): boolean => (formIsDirty || !!dirtyFields[name]) && !!errors[name];

  useEffect(() => {
    setBackPath('/');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.root}>
      <Container className={styles.container}>
        <div className={styles.title}>
          <Trans t={t} i18nKey="user-form.title" />
        </div>

        <div className={styles.required}>
          <Trans t={t} i18nKey="user-form.required-fields" />
        </div>

        <form
          spellCheck={false}
          autoCorrect="off"
          autoComplete="off"
          autoCapitalize="off"
          className={styles.form}
          onSubmit={handleSubmit(onSubmit)}
        >
          {/* GENDER */}
          <FormElement>
            <FormElement.Group>
              <FormField isInvalid={isInvalid('gender')}>
                <FormField.Label>
                  <Trans t={t} i18nKey="user-form.fields.gender.label" /> *
                </FormField.Label>
                <FormField.Select
                  {...register('gender', {
                    required: t('user-form.fields.gender.errors.required') || '',
                  })}
                >
                  {genderOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </FormField.Select>
              </FormField>
            </FormElement.Group>
            <FormElement.Error>{errors.gender?.message}</FormElement.Error>
          </FormElement>

          {/* AGE */}
          <FormElement>
            <FormElement.Group>
              <FormField isInvalid={isInvalid('age')}>
                <FormField.Label>
                  <Trans t={t} i18nKey="user-form.fields.age.label" /> *
                </FormField.Label>
                <FormField.Input
                  type="number"
                  min={CONSTANTS.AGE_MIN}
                  max={CONSTANTS.AGE_MAX}
                  {...register('age', {
                    valueAsNumber: true,
                    required: t('user-form.fields.age.errors.required') || '',
                    min: {
                      value: CONSTANTS.AGE_MIN,
                      message: t('user-form.fields.age.errors.min', { min: CONSTANTS.AGE_MIN }),
                    },
                    max: {
                      value: CONSTANTS.AGE_MAX,
                      message: t('user-form.fields.age.errors.max', { max: CONSTANTS.AGE_MAX }),
                    },
                  })}
                />
              </FormField>
            </FormElement.Group>
            <FormElement.Legend isHidden={isInvalid('age')}>
              <Trans
                t={t}
                i18nKey="user-form.fields.age.legend"
                values={{ min: CONSTANTS.AGE_MIN, max: CONSTANTS.AGE_MAX }}
              />
            </FormElement.Legend>
            <FormElement.Error>{errors.age?.message}</FormElement.Error>
          </FormElement>

          {/* STUDIES LEVEL */}
          <FormElement>
            <FormElement.Group>
              <FormField isInvalid={isInvalid('studiesLevel')}>
                <FormField.Label>
                  <Trans t={t} i18nKey="user-form.fields.studies-level.label" /> *
                </FormField.Label>
                <FormField.Select
                  {...register('studiesLevel', {
                    required: t('user-form.fields.studies-level.errors.required') || '',
                  })}
                >
                  {studiesLevelOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </FormField.Select>
              </FormField>
            </FormElement.Group>
            <FormElement.Error>{errors.studiesLevel?.message}</FormElement.Error>
          </FormElement>

          {/* UNEMPLOYMENT DURATION */}
          <FormElement>
            <FormElement.Group>
              <FormField isInvalid={isInvalid('unemploymentDuration')}>
                <FormField.Label>
                  <Trans t={t} i18nKey="user-form.fields.unemployment-duration.label" /> *
                </FormField.Label>
                <FormField.Input
                  type="number"
                  min={CONSTANTS.UNEMPLOYMENT_DURATION_MIN}
                  max={CONSTANTS.UNEMPLOYMENT_DURATION_MAX}
                  {...register('unemploymentDuration', {
                    valueAsNumber: true,
                    required: t('user-form.fields.unemployment-duration.errors.required') || '',
                    min: {
                      value: CONSTANTS.UNEMPLOYMENT_DURATION_MIN,
                      message: t('user-form.fields.unemployment-duration.errors.min', {
                        min: CONSTANTS.UNEMPLOYMENT_DURATION_MIN,
                      }),
                    },
                    max: {
                      value: CONSTANTS.UNEMPLOYMENT_DURATION_MAX,
                      message: t('user-form.fields.unemployment-duration.errors.max', {
                        max: CONSTANTS.UNEMPLOYMENT_DURATION_MAX,
                      }),
                    },
                  })}
                />
              </FormField>
            </FormElement.Group>
            <FormElement.Legend isHidden={isInvalid('unemploymentDuration')}>
              <Trans
                t={t}
                i18nKey="user-form.fields.unemployment-duration.legend"
                values={{
                  min: CONSTANTS.UNEMPLOYMENT_DURATION_MIN,
                  max: CONSTANTS.UNEMPLOYMENT_DURATION_MAX,
                }}
              />
            </FormElement.Legend>
            <FormElement.Error>{errors.unemploymentDuration?.message}</FormElement.Error>
          </FormElement>

          {/* FINANCIALLY COMPENSATED */}
          <FormElement>
            <FormElement.Group>
              <FormField isInvalid={isInvalid('financiallyCompensated')}>
                <FormField.Label>
                  <Trans t={t} i18nKey="user-form.fields.financially-compensated.label" /> *
                </FormField.Label>
                <FormField.Select
                  {...register('financiallyCompensated', {
                    required: t('user-form.fields.financially-compensated.errors.required') || '',
                  })}
                >
                  {financiallyCompensatedOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </FormField.Select>
              </FormField>
            </FormElement.Group>
            <FormElement.Error>{errors.financiallyCompensated?.message}</FormElement.Error>
          </FormElement>

          {/* FAMILY SITUATION */}
          <FormElement>
            <FormElement.Group>
              <FormField isInvalid={isInvalid('familySituation')}>
                <FormField.Label>
                  <Trans t={t} i18nKey="user-form.fields.family-situation.label" /> *
                </FormField.Label>
                <FormField.Select
                  {...register('familySituation', {
                    required: t('user-form.fields.family-situation.errors.required') || '',
                  })}
                >
                  {situationOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </FormField.Select>
              </FormField>
            </FormElement.Group>
            <FormElement.Error>{errors.familySituation?.message}</FormElement.Error>
          </FormElement>

          {/* OLD JOB */}
          <FormElement>
            <FormElement.Group>
              <FormField isInvalid={isInvalid('oldJob')}>
                <FormField.Label>
                  <Trans t={t} i18nKey="user-form.fields.old-job.label" />
                </FormField.Label>
                <FormField.Textarea {...register('oldJob')} />
              </FormField>
            </FormElement.Group>
            <FormElement.Error>{errors.oldJob?.message}</FormElement.Error>
          </FormElement>

          {/* RESEARCHED JOB */}
          <FormElement>
            <FormElement.Group>
              <FormField isInvalid={isInvalid('researchedJob')}>
                <FormField.Label>
                  <Trans t={t} i18nKey="user-form.fields.researched-job.label" />
                </FormField.Label>
                <FormField.Textarea {...register('researchedJob')} />
              </FormField>
            </FormElement.Group>
            <FormElement.Error>{errors.researchedJob?.message}</FormElement.Error>
          </FormElement>

          {/* RESEARCHED FORMATION */}
          <FormElement>
            <FormElement.Group>
              <FormField isInvalid={isInvalid('researchedFormation')}>
                <FormField.Label>
                  <Trans t={t} i18nKey="user-form.fields.researched-formation.label" />
                </FormField.Label>
                <FormField.Textarea {...register('researchedFormation')} />
              </FormField>
            </FormElement.Group>
            <FormElement.Error>{errors.researchedFormation?.message}</FormElement.Error>
          </FormElement>

          {/* PROFESSIONAL PROJECT */}
          <FormElement>
            <FormElement.Group>
              <FormField isInvalid={isInvalid('professionalProject')}>
                <FormField.Label>
                  <Trans t={t} i18nKey="user-form.fields.professional-project.label" />
                </FormField.Label>
                <FormField.Textarea {...register('professionalProject')} />
              </FormField>
            </FormElement.Group>
            <FormElement.Error>{errors.professionalProject?.message}</FormElement.Error>
          </FormElement>

          {/* MEANS OF TRANSPORT */}
          <FormElement>
            <FormElement.Label>
              <Trans t={t} i18nKey="user-form.fields.means-of-transport.label" /> *
            </FormElement.Label>
            <FormElement.Group className={styles.checkboxes}>
              {meansOfTransportOptions.map((option) => (
                <FormCheckbox id={option.value} key={option.value}>
                  <FormCheckbox.Input
                    value={option.value}
                    {...register('meansOfTransport', {
                      required: t('user-form.fields.means-of-transport.errors.required') || '',
                    })}
                  />
                  <FormCheckbox.Label>{option.label}</FormCheckbox.Label>
                </FormCheckbox>
              ))}
            </FormElement.Group>
            <FormElement.Error>{errors.meansOfTransport?.message}</FormElement.Error>
          </FormElement>

          {/* THRESHOLD SALARY */}
          <FormElement>
            <FormElement.Label>
              <Trans t={t} i18nKey="user-form.fields.threshold-salary.title" /> *
            </FormElement.Label>
            <FormElement.Group className={styles.salary}>
              <FormField isInvalid={isInvalid('thresholdSalary')}>
                <FormField.Label>
                  <Trans t={t} i18nKey="user-form.fields.threshold-salary.label" />
                </FormField.Label>
                <FormField.Select
                  {...register('thresholdSalary', {
                    required: t('user-form.fields.threshold-salary.errors.required') || '',
                  })}
                >
                  {thresholdSalaryOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </FormField.Select>
              </FormField>
            </FormElement.Group>
            <FormElement.Error>{errors.thresholdSalary?.message}</FormElement.Error>
          </FormElement>

          {/* DYSLEXIA */}
          <FormElement>
            <FormElement.Label>
              <Trans t={t} i18nKey="user-form.fields.dyslexia.title" />
            </FormElement.Label>
            <FormElement.Group className={styles.salary}>
              <FormField isInvalid={isInvalid('dyslexia')}>
                <FormField.Label>
                  <Trans t={t} i18nKey="user-form.fields.dyslexia.label" />
                </FormField.Label>
                <FormField.Select {...register('dyslexia')}>
                  {dyslexiaOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </FormField.Select>
              </FormField>
            </FormElement.Group>
            <FormElement.Error>{errors.thresholdSalary?.message}</FormElement.Error>
          </FormElement>

          <Button
            as="button"
            variant="filled"
            disabled={isSubmitting}
            className={styles.button}
            onClick={() => setFormIsDirty(true)}
          >
            <Trans t={t} i18nKey="user-form.submit" />
          </Button>
        </form>
      </Container>
    </div>
  );
};

export default UserQuestionnaire;
