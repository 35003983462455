import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { UserSelectors } from 'core/store/user/user.selectors';

const useChrono = (
  id: number
): {
  startTime: number;
  stopTime: number;
  stop: () => void;
  start: () => void;
} => {
  const [startTime, setStartTime] = useState<number>(0);
  const [stopTime, setStopTime] = useState<number>(0);
  const user = useSelector(UserSelectors.data);
  const key = `${id}-${user?.id || 'user'}-timer`;

  const stop = (): void => {
    setStopTime(Date.now());
  };

  const start = (): void => {
    const storedTime = localStorage.getItem(key);
    const additionalTime = storedTime ? JSON.parse(storedTime) : 0;
    setStartTime(Date.now() - additionalTime);
  };

  const handleUnload = useCallback(() => {
    const globalTime = Date.now() - startTime;
    localStorage.setItem(key, JSON.stringify(globalTime));
  }, [key, startTime]);

  useEffect(() => {
    if (!id) return undefined;

    if (stopTime) {
      localStorage.removeItem(key);
      return undefined;
    }

    window.addEventListener('beforeunload', handleUnload);

    return () => window.removeEventListener('beforeunload', handleUnload);
  }, [stopTime, handleUnload, id, key]);

  return { startTime, stopTime, stop, start };
};

export default useChrono;
