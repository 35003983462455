import { useSelector } from 'react-redux';
import { QuestionnairesSelectors } from 'core/store/questionnaires/questionnaires.selectors';

const useProgress = ():
  | undefined
  | {
      total: number;
      completed: number;
    } => {
  const questionnaires = useSelector(QuestionnairesSelectors.all);

  return questionnaires
    ? { total: questionnaires.length, completed: questionnaires.filter(({ isCompleted }) => isCompleted).length }
    : undefined;
};

export default useProgress;
