import React, { ChangeEvent, useState } from 'react';
import TextareaAutosize, { TextareaAutosizeProps } from 'react-textarea-autosize';
import styles from './Textarea.module.css';

type Props = {
  className?: string;
};

export type TextareaProps = Omit<TextareaAutosizeProps, keyof Props> & Props & React.RefAttributes<HTMLTextAreaElement>;

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, ...props }, ref): JSX.Element | null => {
    const [isEmpty, setIsEmpty] = useState<boolean>(!props.value && !props.defaultValue);
    const innerRef = React.useRef<HTMLTextAreaElement>(null);

    const onChange = (event: ChangeEvent<HTMLTextAreaElement>): void => {
      setIsEmpty(!event.target.value);
      props.onChange?.(event);
    };

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    React.useImperativeHandle(ref, () => innerRef.current!);

    React.useEffect(() => {
      if (!innerRef.current) return;
      setIsEmpty(!innerRef.current.value);
    }, []);

    return (
      <TextareaAutosize
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        ref={innerRef}
        onChange={onChange}
        data-empty={isEmpty}
        className={`${styles.root} ${className}`}
      />
    );
  }
) as React.ForwardRefExoticComponent<TextareaProps>;

export default Textarea;
