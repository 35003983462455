import React from 'react';
import Helper from 'core/helpers/component.helper';
import styles from './FormField.module.css';
import Input, { InputProps } from './Input';
import Label, { LabelProps } from './Label';
import Select, { SelectProps } from './Select';
import Textarea, { TextareaProps } from './Textarea';

type Props = {
  isInvalid?: boolean;
};

export type FormFieldProps = Omit<React.HTMLProps<HTMLFieldSetElement>, keyof Props> & Props;

type FormFieldComponent = React.ForwardRefExoticComponent<FormFieldProps> & {
  Label: React.ForwardRefExoticComponent<LabelProps>;
  Input: React.ForwardRefExoticComponent<InputProps>;
  Select: React.ForwardRefExoticComponent<SelectProps>;
  Textarea: React.ForwardRefExoticComponent<TextareaProps>;
};

const FormField: FormFieldComponent = React.forwardRef<HTMLFieldSetElement, FormFieldProps>(
  ({ isInvalid, children, className, ...props }, ref): JSX.Element => {
    const label = Helper.findElementByType<LabelProps>(children, Label);
    const input = Helper.findElementByType<InputProps>(children, Input);
    const select = Helper.findElementByType<SelectProps>(children, Select);
    const textarea = Helper.findElementByType<TextareaProps>(children, Textarea);

    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <fieldset {...props} ref={ref} data-is-invalid={isInvalid} className={`${styles.root} ${className}`}>
        {Helper.cloneElement<InputProps>(input, { className: `${input?.props.className} ${styles.input}` })}
        {Helper.cloneElement<SelectProps>(select, { className: `${select?.props.className} ${styles.select}` })}
        {Helper.cloneElement<TextareaProps>(textarea, { className: `${textarea?.props.className} ${styles.textarea}` })}
        {Helper.cloneElement<LabelProps>(label, { className: `${label?.props.className} ${styles.label}`, isInvalid })}
      </fieldset>
    );
  }
) as FormFieldComponent;

FormField.Input = Input;

FormField.Label = Label;

FormField.Select = Select;

FormField.Textarea = Textarea;

export default FormField;
