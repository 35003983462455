import React from 'react';
import styles from './Group.module.css';

type Props = {
  className?: string;
  children?: React.ReactNode;
};

export type GroupProps = Omit<React.HTMLProps<HTMLDivElement>, keyof Props> & Props;

const Group = React.forwardRef<HTMLDivElement, GroupProps>(
  ({ children, className, ...props }, ref): JSX.Element | null => {
    if (!children) return null;

    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <div {...props} ref={ref} className={`${styles.root} ${className}`}>
        {children}
      </div>
    );
  }
) as React.ForwardRefExoticComponent<GroupProps>;

export default Group;
