import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import fr from './locales/fr.json';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    lng: 'fr',
    resources: { fr: { translation: fr } },
    interpolation: { escapeValue: false },
  });

export default i18n;
