import { ForwardRef } from '@sbthuman/survey-front';
import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { CheckHelper } from 'core/helpers/check.helper';
import { PostMessageHelper } from 'core/helpers/post-message.helper';
import { Report } from 'core/models/questionnaire.model';
import { QuestionnairesService } from 'core/services/questionnaires.service';
import { AppSelectors } from 'core/store/app/app.selectors';
import { setCompleted } from 'core/store/questionnaires/questionnaires.slice';
import { AppDispatch } from 'core/store/store';
import { QuestionnairesSelectors } from '../store/questionnaires/questionnaires.selectors';

const useReport = (
  id: number
): {
  getReport: (entries: ForwardRef['surveyEntries'], time: number) => Promise<void>;
  data: Report | undefined;
  error: string | null;
  pending: boolean;
} => {
  const dispatch = useDispatch<AppDispatch>();
  const { token, contextId, taskId } = useSelector(AppSelectors.state);
  const nextQuestionnaire = useSelector(QuestionnairesSelectors.nextById(id));

  const [data, setData] = useState<Report | undefined>();
  const [error, setError] = useState<string | null>(null);
  const [pending, setPending] = useState<boolean>(false);

  useEffect(() => {
    setData(undefined);
    setPending(false);
    setError(null);
  }, [id]);

  const getReport = async (entries: ForwardRef['surveyEntries'], time: number): Promise<void> => {
    if (!token || !contextId || !taskId) return;
    setError(null);
    setPending(true);
    try {
      const timeInSeconds = Math.round(time / 1000);
      const report = await QuestionnairesService.postResult(token, taskId, contextId, id, entries, time);
      PostMessageHelper.sendResult(id, timeInSeconds, report.badges, report.text, report.date, !nextQuestionnaire);
      dispatch(setCompleted({ id }));
      setData(report);
    } catch (e) {
      const message = CheckHelper.hasErrorMessage<{ message: string }>(e) ? e.message : 'Unknown error';
      PostMessageHelper.error(message);
      toast.error(message);
      setError(message);
    } finally {
      setPending(false);
    }
  };

  return { getReport, data, error, pending };
};

export default useReport;
