import React, { ChangeEvent, KeyboardEvent, useState } from 'react';
import styles from './Input.module.css';

type Props = {
  className?: string;
};

export type InputProps = Omit<React.HTMLProps<HTMLInputElement>, keyof Props> & Props;

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ type, className, ...props }, ref): JSX.Element | null => {
    const [isEmpty, setIsEmpty] = useState<boolean>(true);
    const innerRef = React.useRef<HTMLInputElement>(null);

    const onChange = (event: ChangeEvent<HTMLInputElement>): void => {
      setIsEmpty(!event.target.value);
      props.onChange?.(event);
    };

    const onKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
      if (type !== 'number') return true;
      const regex = /(^\d*$)|(Backspace|Tab|Delete|ArrowLeft|ArrowRight)/;
      return !event.key.match(regex) && event.preventDefault();
    };

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    React.useImperativeHandle(ref, () => innerRef.current!);

    React.useEffect(() => {
      if (!innerRef.current) return;
      setIsEmpty(!innerRef.current.value);
    }, []);

    return (
      <input
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        type={type}
        ref={innerRef}
        autoCorrect="off"
        autoComplete="off"
        autoCapitalize="off"
        spellCheck={false}
        onChange={onChange}
        data-empty={isEmpty}
        onKeyDown={onKeyDown}
        onPaste={(e) => e.preventDefault()}
        className={`${styles.root} ${className}`}
      />
    );
  }
) as React.ForwardRefExoticComponent<InputProps>;

export default Input;
