import React, { SVGProps } from 'react';

const ChevronRight = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width="8" height="16" viewBox="0 0 8 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M0 13.0185L5 8L0 2.98152L1 0.974121L8 8L1 15.0259L0 13.0185Z" fill="currentColor" />
  </svg>
);

export default ChevronRight;
