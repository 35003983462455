import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import initialState from './header.default';
import { HeaderState } from './header.model';

const headerSlice = createSlice({
  name: 'header',
  initialState,
  reducers: {
    patchHeaderState: (state, { payload }: PayloadAction<Partial<HeaderState>>) => ({ ...state, ...payload }),
  },
});

export const { patchHeaderState } = headerSlice.actions;

export default headerSlice.reducer;
