import { createAsyncThunk } from '@reduxjs/toolkit';
import { CheckHelper } from '../../helpers/check.helper';
import { Questionnaire } from '../../models/questionnaire.model';
import { QuestionnairesService } from '../../services/questionnaires.service';
import { Store } from '../store.model';

export class QuestionnairesThunks {
  static get = createAsyncThunk<Questionnaire[]>('getQuestionnaires', async (_, { getState, rejectWithValue }) => {
    try {
      const { app } = getState() as Store;
      if (!app.contextId) return rejectWithValue(new Error('Context ID is missing'));
      if (!app.token) return rejectWithValue(new Error('JWT token is missing'));
      return await QuestionnairesService.get(app.token, app.contextId);
    } catch (e: unknown) {
      return CheckHelper.hasErrorMessage<{ message: string }>(e)
        ? rejectWithValue(new Error(e.message))
        : rejectWithValue(new Error('Unknown error'));
    }
  });
}
