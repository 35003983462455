import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import styles from './Button.module.css';

type Variant = 'filled' | 'outlined';

type Size = 'sm' | 'md';

type ButtonProps<T extends React.ElementType> = {
  as?: T;
  size?: Size;
  variant?: Variant;
  ref?: PolymorphicRef<T>;
};

type Props<T extends React.ElementType> = Omit<React.ComponentPropsWithoutRef<T>, keyof ButtonProps<T>> &
  (T extends 'a' ? ButtonProps<T> & LinkProps : ButtonProps<T>) &
  Omit<React.HTMLAttributes<T>, keyof ButtonProps<T>>;

type PolymorphicRef<T extends React.ElementType> = React.ComponentPropsWithRef<T>['ref'];

type ButtonComponent = <T extends 'button' | 'a'>(props: Props<T>) => React.ReactElement | null;

const Button: ButtonComponent = React.forwardRef(
  <T extends React.ElementType>(
    { as, size, variant, className, children, ...props }: Props<T>,
    ref?: PolymorphicRef<T>
  ): JSX.Element | null => {
    const Component = as === 'a' ? Link : as || 'button';
    return (
      <Component
        {...props}
        ref={ref}
        data-variant={variant}
        data-size={size || 'md'}
        className={`${styles.root} ${className}`}
      >
        {children}
      </Component>
    );
  }
);

export default Button;
