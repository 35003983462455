import { SurveyProps } from '@sbthuman/survey-front';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CheckHelper } from 'core/helpers/check.helper';
import { PostMessageHelper } from 'core/helpers/post-message.helper';
import { QuestionnairesService } from 'core/services/questionnaires.service';
import { AppSelectors } from 'core/store/app/app.selectors';

const useConfig = (
  id: number
): {
  data: SurveyProps['config'] | undefined;
  error: string | null;
  pending: boolean;
} => {
  const { token } = useSelector(AppSelectors.state);
  const [data, setData] = useState<SurveyProps['config'] | undefined>();
  const [error, setError] = useState<string | null>(null);
  const [pending, setPending] = useState<boolean>(true);

  useEffect(() => {
    if (!token) return;

    setData(undefined);
    setPending(true);
    setError(null);

    QuestionnairesService.getConfig(token, id)
      .then((c) => {
        setData(c);
      })
      .catch((e) => {
        const message = CheckHelper.hasErrorMessage<{ message: string }>(e) ? e.message : 'Unknown error';
        PostMessageHelper.error(message);
        setError(message);
      })
      .finally(() => {
        setPending(false);
      });
  }, [id, token]);

  return { data, error, pending };
};

export default useConfig;
