import React, { ChangeEvent, useState } from 'react';
import { ChevronDown } from 'components/Icons';
import styles from './Select.module.css';

type Props = {
  className?: string;
};

export type SelectProps = Omit<React.HTMLProps<HTMLSelectElement>, keyof Props> & Props;

const Select = React.forwardRef<HTMLSelectElement, SelectProps>(({ className, ...props }, ref): JSX.Element | null => {
  const [isEmpty, setIsEmpty] = useState<boolean>(!props.value && !props.defaultValue);
  const innerRef = React.useRef<HTMLSelectElement>(null);

  const onChange = (event: ChangeEvent<HTMLSelectElement>): void => {
    setIsEmpty(!event.target.value);
    props.onChange?.(event);
  };

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  React.useImperativeHandle(ref, () => innerRef.current!);

  React.useEffect(() => {
    if (!innerRef.current) return;
    setIsEmpty(!innerRef.current.value);
  }, []);

  return (
    <>
      <select
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        ref={innerRef}
        onChange={onChange}
        data-empty={isEmpty}
        defaultValue={props.defaultValue || ''}
        className={`${styles.root} ${className}`}
      >
        <option value="" disabled hidden>
          {' '}
        </option>
        {props.children}
      </select>
      <ChevronDown className={styles.chevron} />
    </>
  );
}) as React.ForwardRefExoticComponent<SelectProps>;

export default Select;
