import React, { cloneElement, isValidElement, Children } from 'react';
import { OneOrMany } from 'core/models/shared.model';
import type { Attributes, ComponentProps, FC, ReactElement, ReactNode } from 'react';

class ComponentHelper {
  static findElementByType = <T>(
    children: ReactNode,
    components: OneOrMany<FC<T>>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): (ReactElement<ComponentProps<FC<T>>, FC<T>> & { ref?: any }) | undefined => {
    const element = Children.toArray(children).find((child) => {
      if (!React.isValidElement(child)) return false;
      return Array.isArray(components) ? components.some((comp) => child.type === comp) : child.type === components;
    });
    return element as ReactElement<ComponentProps<FC<T>>, FC<T>> | undefined;
  };

  static cloneElement = <T>(
    element: ReactElement<ComponentProps<FC<T>>, FC<T>> | undefined,
    props: Partial<T> & Attributes = {}
  ): ReactElement<T> | undefined => {
    if (!isValidElement(element)) return undefined;
    return cloneElement(element, props);
  };
}

export default ComponentHelper;
