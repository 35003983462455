import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import styles from './ButtonIcon.module.css';

type ButtonIconProps<T extends React.ElementType> = {
  as?: T;
  ref?: PolymorphicRef<T>;
};

type Props<T extends React.ElementType> = Omit<React.ComponentPropsWithoutRef<T>, keyof ButtonIconProps<T>> &
  (T extends 'a' ? ButtonIconProps<T> & LinkProps : ButtonIconProps<T>) &
  Omit<React.HTMLAttributes<T>, keyof ButtonIconProps<T>>;

type PolymorphicRef<T extends React.ElementType> = React.ComponentPropsWithRef<T>['ref'];

type ButtonIconComponent = <T extends 'button' | 'a'>(props: Props<T>) => React.ReactElement | null;

const ButtonIcon: ButtonIconComponent = React.forwardRef(
  <T extends React.ElementType>(
    { as, className, children, ...props }: Props<T>,
    ref?: PolymorphicRef<T>
  ): JSX.Element | null => {
    const Component = as === 'a' ? Link : as || 'button';
    return (
      <Component {...props} ref={ref} className={`${styles.root} ${className}`}>
        {children}
      </Component>
    );
  }
);

export default ButtonIcon;
