import { API } from 'aws-amplify';
import { User, UserData } from 'core/models/user.model';

export class UsersService {
  static postMe = async (token: string): Promise<User> => {
    const { data } = await API.post(process.env.REACT_APP_API_NAME || '', `/users/me`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      response: true,
    });
    return data;
  };

  static putMe = async (token: string, userData: UserData): Promise<UserData> => {
    await API.put(process.env.REACT_APP_API_NAME || '', `/users/me`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: userData,
      response: true,
    });
    return userData;
  };
}
