import React, { HTMLProps } from 'react';
import styles from './Progress.module.css';

type Props = HTMLProps<HTMLDivElement> & {
  total?: number;
  completed?: number;
};

const Progress = React.forwardRef<HTMLDivElement, Props>(
  ({ completed, total, className, ...props }, ref): JSX.Element | null => {
    if (!total || !completed) return null;

    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <div {...props} ref={ref} className={`${styles.root} ${className}`}>
        {Array.from({ length: total }, (_, i) => i).map((i) => (
          <div key={i} className={styles.cell} data-filled={completed > i} />
        ))}
      </div>
    );
  }
);

export default Progress;
