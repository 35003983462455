import React, { useEffect, useState } from 'react';
import ButtonIcon from '../ButtonIcon';
import styles from './Speech.module.css';

type Props = {
  readonly url?: string;
  readonly className?: string;
};

type State = 'idle' | 'playing' | 'paused';

const Speech = React.forwardRef<HTMLButtonElement, Props>(({ url, className }, forwardRef): JSX.Element | null => {
  const [ready, setReady] = useState<boolean>(false);
  const [state, setState] = useState<State>('idle');
  const ref = React.createRef<HTMLAudioElement>();

  useEffect(() => {
    const audio = ref.current;
    if (!audio) return undefined;

    audio.oncanplaythrough = () => setReady(true);
    audio.onended = () => {
      audio.load();
      setState('idle');
    };

    return () => {
      audio.oncanplaythrough = null;
      audio.onended = null;
    };
  }, [ref]);

  const handlePlayPause = () => {
    if (!ready) return;
    if (state === 'idle' || state === 'paused') {
      ref.current?.play();
      setState('playing');
    } else {
      ref.current?.pause();
      setState('paused');
    }
  };

  if (!url) return null;

  return (
    <ButtonIcon as="button" ref={forwardRef} onClick={handlePlayPause} className={`${styles.root} ${className}`}>
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <audio ref={ref} src={url} preload="metadata" />
      <div className={styles.icon} data-state={state} data-ready={ready} />
    </ButtonIcon>
  );
});

export default Speech;
