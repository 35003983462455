import { Amplify } from 'aws-amplify';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Toaster } from 'react-hot-toast';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import amplify from 'core/constants/amplify';
import { store } from 'core/store/store';
import MainWrapper from 'wrappers/MainWrapper';
import App from './App';
import Initializer from './Initializer';
import 'assets/styles/main.css';
import 'core/i18n';

Amplify.configure({ ...amplify });

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <MainWrapper>
        <Initializer>
          <Toaster />
          <App />
        </Initializer>
      </MainWrapper>
    </BrowserRouter>
  </Provider>
);
