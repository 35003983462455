import React from 'react';
import styles from './Label.module.css';

export type Props = {
  className?: string;
  isInvalid?: boolean;
  children?: React.ReactNode;
};

export type LabelProps = Omit<React.HTMLProps<HTMLDivElement>, keyof Props> & Props;

const Label = React.forwardRef<HTMLDivElement, LabelProps>(
  ({ isInvalid, children, className, ...props }, ref): JSX.Element | null => {
    if (!children) return null;

    return (
      <>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <div {...props} ref={ref} className={`${styles.root} ${className}`} data-is-invalid={isInvalid}>
          {children}
        </div>
        <legend>{children}</legend>
      </>
    );
  }
) as React.ForwardRefExoticComponent<LabelProps>;

export default Label;
