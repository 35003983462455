import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { PostMessageHelper } from 'core/helpers/post-message.helper';
import { QuestionnairesSelectors } from 'core/store/questionnaires/questionnaires.selectors';

const useNext = (id: number): { isLast: boolean; onClick: () => void } => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const nextQuestionnaire = useSelector(QuestionnairesSelectors.nextById(id));

  const onClick = (): void => {
    if (nextQuestionnaire) navigate(`/questionnaires/${nextQuestionnaire.id}${search}`);
    else PostMessageHelper.closeIframe();
  };

  return { isLast: !nextQuestionnaire, onClick };
};

export default useNext;
