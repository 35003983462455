import React from 'react';
import styles from './Legend.module.css';

type Props = {
  isHidden?: boolean;
  className?: string;
  children?: React.ReactNode;
};

export type LegendProps = Omit<React.HTMLProps<HTMLDivElement>, keyof Props> & Props;

const Legend = React.forwardRef<HTMLDivElement, LegendProps>(
  ({ isHidden, children, className, ...props }, ref): JSX.Element | null => {
    if (!children) return null;

    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <div {...props} ref={ref} className={`${styles.root} ${className}`} data-is-hidden={isHidden}>
        {children}
      </div>
    );
  }
) as React.ForwardRefExoticComponent<LegendProps>;

export default Legend;
