import React, { SVGProps } from 'react';

const Index = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
    <path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="m19 9l-7 7l-7-7"
    />
  </svg>
);

export default Index;
