import React from 'react';
import Helper from 'core/helpers/component.helper';
import Error, { ErrorProps } from './Error';
import styles from './FormElement.module.css';
import Group, { GroupProps } from './Group';
import Label, { LabelProps } from './Label';
import Legend, { LegendProps } from './Legend';

type Props = {
  isInvalid?: boolean;
};

type FormElementProps = Omit<React.HTMLProps<HTMLDivElement>, keyof Props> & Props;

type FormElementComponent = React.ForwardRefExoticComponent<FormElementProps> & {
  Legend: React.ForwardRefExoticComponent<LegendProps>;
  Label: React.ForwardRefExoticComponent<LabelProps>;
  Error: React.ForwardRefExoticComponent<ErrorProps>;
  Group: React.ForwardRefExoticComponent<GroupProps>;
};

const FormElement: FormElementComponent = React.forwardRef<HTMLDivElement, FormElementProps>(
  ({ isInvalid, children, className, ...props }, ref): JSX.Element => {
    const label = Helper.findElementByType<LabelProps>(children, Label);
    const group = Helper.findElementByType<GroupProps>(children, Group);
    const error = Helper.findElementByType<ErrorProps>(children, Error);
    const legend = Helper.findElementByType<LegendProps>(children, Legend);

    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <div {...props} ref={ref} className={`${styles.root} ${className}`} data-is-invalid={isInvalid}>
        {Helper.cloneElement<LabelProps>(label, { className: `${styles.label} ${label?.props.className}` })}
        {Helper.cloneElement<GroupProps>(group, { className: `${styles.group} ${group?.props.className}` })}
        {Helper.cloneElement<ErrorProps>(error, { className: `${styles.error} ${error?.props.className}` })}
        {Helper.cloneElement<LegendProps>(legend, { className: `${styles.legend} ${legend?.props.className}` })}
      </div>
    );
  }
) as FormElementComponent;

FormElement.Group = Group;

FormElement.Label = Label;

FormElement.Error = Error;

FormElement.Legend = Legend;

export default FormElement;
