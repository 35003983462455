import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Container from 'components/Container';
import Header from 'components/Header';
import styles from './MainWrapper.module.css';

type Props = {
  children: React.ReactNode;
};

const MainWrapper: React.FC<Props> = ({ children }): JSX.Element | null => {
  const { pathname } = useLocation();

  useEffect(() => {
    const onResize = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };
    window.addEventListener('resize', onResize);
    onResize();

    return () => window.removeEventListener('resize', onResize);
  }, []);

  useEffect(() => {
    document.documentElement.scrollTo({ top: 0, left: 0, behavior: 'auto' });
  }, [pathname]);

  return (
    <div className={styles.root}>
      <Header />
      <Container className={styles.container}>{children}</Container>
    </div>
  );
};

export default MainWrapper;
