import { ForwardRef, SurveyProps } from '@sbthuman/survey-front';
import { API } from 'aws-amplify';
import { Questionnaire, Report } from '../models/questionnaire.model';

export class QuestionnairesService {
  static get = async (token: string, contextId: string): Promise<Questionnaire[]> => {
    const { data } = await API.get(process.env.REACT_APP_API_NAME || '', `/questionnaires`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      queryStringParameters: { contextId },
      response: true,
    });
    return data.questionnaires;
  };

  static getConfig = async (token: string, id: number): Promise<SurveyProps['config']> => {
    const { data } = await API.get(process.env.REACT_APP_API_NAME || '', `/questionnaires/configs/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      response: true,
    });
    return { ...data, outro: { title: '', text: '' } };
  };

  static postResult = async (
    token: string,
    taskId: string,
    contextId: string,
    id: number,
    entries: ForwardRef['surveyEntries'],
    time: number
  ): Promise<Report> => {
    const { data } = await API.post(process.env.REACT_APP_API_NAME || '', `/questionnaires/results/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      queryStringParameters: { contextId },
      response: true,
      body: { answers: entries, time, taskId },
    });
    return data;
  };
}
