import React, { HTMLProps } from 'react';
import styles from './Container.module.css';

type Props = HTMLProps<HTMLDivElement> & {
  className?: string;
  children?: React.ReactNode;
};

const Container = React.forwardRef<HTMLDivElement, Props>(
  ({ className, children, ...props }, ref): JSX.Element | null => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div {...props} ref={ref} className={`${styles.root} ${className}`}>
      {children}
    </div>
  )
);

export default Container;
