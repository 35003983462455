import { ReportBadge } from '../models/questionnaire.model';

export class PostMessageHelper {
  private static post = (message: string): void => {
    if (
      'ReactNativeWebView' in window &&
      window.ReactNativeWebView &&
      typeof window.ReactNativeWebView === 'object' &&
      'postMessage' in window.ReactNativeWebView &&
      typeof window.ReactNativeWebView.postMessage === 'function'
    )
      window.ReactNativeWebView.postMessage(message);
    else window.postMessage(message, '*');
  };

  static closeIframe = (): void => {
    const message = {
      type: 'ActivityExited',
      payload: null,
    };
    PostMessageHelper.post(JSON.stringify(message));
  };

  static error = (errorMessage: string): void => {
    const message = {
      activityEvent: 'ActivityError',
      payload: errorMessage,
    };
    PostMessageHelper.post(JSON.stringify(message));
  };

  static sendResult = (
    activityId: number,
    globalDuration: number,
    badges: ReportBadge[],
    text: string,
    date: Date,
    complete: boolean
  ): void => {
    const message = {
      type: 'ActivitySaved',
      payload: {
        text,
        date,
        badges,
        complete,
        activityId,
        globalDuration,
      },
    };
    PostMessageHelper.post(JSON.stringify(message));
  };
}
