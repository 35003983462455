import { createAsyncThunk } from '@reduxjs/toolkit';
import { CheckHelper } from 'core/helpers/check.helper';
import { User, UserData } from 'core/models/user.model';
import { UsersService } from 'core/services/users.service';
import { Store } from '../store.model';

export class UserThunks {
  static postMe = createAsyncThunk<User>('postMe', async (_, { getState, rejectWithValue }) => {
    try {
      const { app } = getState() as Store;
      if (!app.token) return rejectWithValue(new Error('JWT token is missing'));
      return await UsersService.postMe(app.token);
    } catch (e: unknown) {
      return CheckHelper.hasErrorMessage<{ message: string }>(e)
        ? rejectWithValue(new Error(e.message))
        : rejectWithValue(new Error('Unknown error'));
    }
  });

  static putMe = createAsyncThunk<UserData, UserData>('putMe', async (data, { getState, rejectWithValue }) => {
    try {
      const { app } = getState() as Store;
      if (!app.token) return rejectWithValue('JWT token is missing');
      await UsersService.putMe(app.token, data);
      return data;
    } catch (e: unknown) {
      return CheckHelper.hasErrorMessage<{ message: string }>(e)
        ? rejectWithValue(new Error(e.message))
        : rejectWithValue(new Error('Unknown error'));
    }
  });
}
