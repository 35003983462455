import { ActionReducerMapBuilder, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CheckHelper } from 'core/helpers/check.helper';
import { User, UserData } from 'core/models/user.model';
import initialState from './user.default';
import { UserState } from './user.model';
import { UserThunks } from './user.thunks';

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<UserState>) => {
    builder.addCase(UserThunks.postMe.pending, (state) => {
      state.pending = true;
    });
    builder.addCase(UserThunks.postMe.rejected, (state, action) => {
      state.pending = false;
      state.error = CheckHelper.hasErrorMessage<{ message: string }>(action.payload)
        ? action.payload.message
        : action.error.message || 'Unknown error';
    });
    builder.addCase(UserThunks.postMe.fulfilled, (state, action: PayloadAction<User>) => {
      state.data = action.payload;
      state.pending = false;
    });

    builder.addCase(UserThunks.putMe.pending, (state) => {
      state.pending = true;
    });
    builder.addCase(UserThunks.putMe.rejected, (state, action) => {
      state.pending = false;
      state.error = CheckHelper.hasErrorMessage<{ message: string }>(action.payload)
        ? action.payload.message
        : action.error.message || 'Unknown error';
    });
    builder.addCase(UserThunks.putMe.fulfilled, (state, action: PayloadAction<UserData>) => {
      state.data = state.data?.id ? { ...state.data, ...action.payload } : state.data;
      state.pending = false;
    });
  },
});

export default userSlice.reducer;
