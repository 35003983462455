import React from 'react';
import { Check } from 'components/Icons';
import styles from './Input.module.css';

type Props = {
  className?: string;
};

export type InputProps = Omit<React.HTMLProps<HTMLInputElement>, keyof Props | 'type'> & Props;

const Input = React.forwardRef<HTMLInputElement, InputProps>(({ className, ...props }, ref): JSX.Element | null => (
  <>
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    <input {...props} ref={ref} type="checkbox" className={styles.input} />
    <div className={`${styles.root} ${className}`}>
      <Check className={styles.mark} />
    </div>
  </>
)) as React.ForwardRefExoticComponent<InputProps>;

export default Input;
