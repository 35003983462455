import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { QuestionnairesSelectors } from 'core/store/questionnaires/questionnaires.selectors';

const useCompleted = (id: number): void => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const questionnaire = useSelector(QuestionnairesSelectors.oneById(id));

  useEffect(() => {
    if (!questionnaire) return;
    if (questionnaire.isCompleted) navigate(`/questionnaires${search}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, navigate, search]);
};

export default useCompleted;
