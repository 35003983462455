import { ActionReducerMapBuilder, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CheckHelper } from 'core/helpers/check.helper';
import { Questionnaire } from 'core/models/questionnaire.model';
import initialState from './questionnaires.default';
import { QuestionnairesState } from './questionnaires.model';
import { QuestionnairesThunks } from './questionnaires.thunks';

const questionnairesSlice = createSlice({
  name: 'questionnaires',
  initialState,
  reducers: {
    setCompleted: (state, { payload }: PayloadAction<{ id: number }>) => {
      state.data = state.data?.length
        ? state.data.map((q) => (q.id === payload.id ? { ...q, isCompleted: true } : q))
        : state.data;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<QuestionnairesState>) => {
    builder.addCase(QuestionnairesThunks.get.pending, (state) => {
      state.pending = true;
    });
    builder.addCase(QuestionnairesThunks.get.rejected, (state, action) => {
      state.pending = false;
      state.error = CheckHelper.hasErrorMessage<{ message: string }>(action.payload)
        ? action.payload.message
        : action.error.message || 'Unknown error';
    });
    builder.addCase(QuestionnairesThunks.get.fulfilled, (state, action: PayloadAction<Questionnaire[]>) => {
      state.data = action.payload;
      state.pending = false;
    });
  },
});

export const { setCompleted } = questionnairesSlice.actions;

export default questionnairesSlice.reducer;
