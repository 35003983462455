import React, { HTMLProps, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { PostMessageHelper } from 'core/helpers/post-message.helper';
import HeaderSelectors from 'core/store/header/header.selectors';
import { patchHeaderState } from 'core/store/header/header.slice';
import { AppDispatch } from 'core/store/store';
import ButtonIcon from '../ButtonIcon';
import { LeftArrow } from '../Icons';
import styles from './Header.module.css';

type Props = HTMLProps<HTMLDivElement> & {
  className?: string;
};

const Header = React.forwardRef<HTMLDivElement, Props>(({ className, ...props }, ref): JSX.Element | null => {
  const [showShadow, setShowShadow] = useState<boolean>(false);
  const [showBack, setShowBack] = useState<boolean>(false);
  const { backPath, show } = useSelector(HeaderSelectors.state);
  const dispatch = useDispatch<AppDispatch>();
  const { pathname, search } = useLocation();

  useEffect(() => {
    const onScroll = () => {
      setShowShadow(document.documentElement.scrollTop > 0);
    };
    window.addEventListener('scroll', onScroll);
    onScroll();

    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  useEffect(() => {
    setShowBack(!!backPath || window.self !== window.top);
    dispatch(patchHeaderState({ show: true }));
  }, [backPath, dispatch, pathname]);

  if (!show) return null;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div {...props} ref={ref} className={`${styles.root} ${className}`} data-shadow={showShadow}>
      {showBack &&
        (!backPath ? (
          <ButtonIcon className={styles.back} onClick={() => PostMessageHelper.closeIframe()}>
            <LeftArrow />
          </ButtonIcon>
        ) : (
          <ButtonIcon as="a" to={`${backPath}${search}`} className={styles.back}>
            <LeftArrow />
          </ButtonIcon>
        ))}
    </div>
  );
});

export default Header;
