import React, { SVGProps } from 'react';

const Index = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width="139" height="141" viewBox="0 0 139 141" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M333.447 -7.90805C359.193 -80.0975 321.451 -159.522 249.149 -185.309C176.847 -211.095 97.3629 -173.477 71.617 -101.288C45.8711 -29.0983 83.6126 50.3266 155.915 76.1127C228.217 101.899 307.701 64.2814 333.447 -7.90805Z"
      fill="#3A00CA"
    />
    <path
      d="M206.558 -120.3C98.7039 -172.846 -46.2512 -10.3685 15.1325 7.18183C76.5162 24.7322 83.3678 51.2364 92.4618 69.9567C101.556 88.677 101.118 142.907 165.464 139.853C229.811 136.798 314.412 -67.7542 206.558 -120.3Z"
      stroke="#FB3A72"
      strokeDasharray="6 6"
    />
  </svg>
);

export default Index;
