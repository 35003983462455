import { useDispatch } from 'react-redux';
import { patchHeaderState } from '../store/header/header.slice';
import { AppDispatch } from '../store/store';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useHeader = () => {
  const dispatch = useDispatch<AppDispatch>();

  const setBackPath = (path: string | null) => dispatch(patchHeaderState({ backPath: path }));

  const hideHeader = () => dispatch(patchHeaderState({ show: false }));

  return { setBackPath, hideHeader };
};

export default useHeader;
