import { ArrayHelper } from 'core/helpers/array.helper';
import { Questionnaire } from 'core/models/questionnaire.model';
import { Store } from '../store.model';
import { QuestionnairesState } from './questionnaires.model';

export class QuestionnairesSelectors {
  static state = (store: Store): QuestionnairesState => store.questionnaires;

  static all = (store: Store): Questionnaire[] | null => store.questionnaires.data;

  static oneById =
    (id?: number) =>
    (store: Store): Questionnaire | undefined =>
      store.questionnaires.data?.find((questionnaire) => questionnaire.id === id);

  static nextById =
    (id?: number) =>
    (store: Store): Questionnaire | undefined =>
      store.questionnaires.data?.length && id
        ? ArrayHelper.findNextItem(store.questionnaires.data, id) || undefined
        : undefined;
}
