import { combineReducers, configureStore, Middleware } from '@reduxjs/toolkit';
import appReducer from 'core/store/app/app.slice';
import headerReducer from 'core/store/header/header.slice';
import questionnairesReducer from 'core/store/questionnaires/questionnaires.slice';
import userReducer from 'core/store/user/user.slice';
import { Store } from './store.model';

export type AppDispatch = typeof store.dispatch;

const reducer = combineReducers<Store>({
  app: appReducer,
  user: userReducer,
  header: headerReducer,
  questionnaires: questionnairesReducer,
});

const middlewares: Middleware[] = [];

if (process.env.NODE_ENV !== 'production') {
  import('redux-logger').then(({ createLogger }) => {
    const logger = createLogger({ collapsed: true, duration: true });
    middlewares.push(logger);
  });
}

export const store = configureStore({
  reducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat(middlewares),
});
