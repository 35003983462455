import React from 'react';
import Helper from 'core/helpers/component.helper';
import styles from './FormCheckbox.module.css';
import Input, { InputProps } from './Input';
import Label, { LabelProps } from './Label';

type Props = {
  id: string;
};

export type FormCheckboxProps = Omit<React.HTMLProps<HTMLLabelElement>, keyof Props> & Props;

type FormCheckboxComponent = React.ForwardRefExoticComponent<FormCheckboxProps> & {
  Label: React.ForwardRefExoticComponent<LabelProps>;
  Input: React.ForwardRefExoticComponent<InputProps>;
};

const FormCheckbox: FormCheckboxComponent = React.forwardRef<HTMLLabelElement, FormCheckboxProps>(
  ({ id, children, className, ...props }, ref): JSX.Element => {
    const input = Helper.findElementByType<InputProps>(children, Input);
    const label = Helper.findElementByType<LabelProps>(children, Label);

    return (
      <label
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        ref={ref}
        htmlFor={id}
        className={`${styles.root} ${className}`}
      >
        {Helper.cloneElement<InputProps>(input, { className: `${styles.input} ${input?.props.className}`, id })}
        {Helper.cloneElement<LabelProps>(label, { className: `${styles.label} ${label?.props.className}` })}
      </label>
    );
  }
) as FormCheckboxComponent;

FormCheckbox.Input = Input;

FormCheckbox.Label = Label;

export default FormCheckbox;
