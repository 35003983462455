import React from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import Home from 'pages/Home';
import Questionnaire from 'pages/Questionnaire';
import Questionnaires from 'pages/Questionnaires';
import UserQuestionnaire from 'pages/UserQuestionnaire';
import QuestionnairesWrapper from 'wrappers/QuestionnairesWrapper';

const App: React.FC = (): JSX.Element => {
  const { search } = useLocation();

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/user-questionnaire" element={<UserQuestionnaire />} />
      <Route path="/questionnaires" element={<QuestionnairesWrapper />}>
        <Route index element={<Questionnaires />} />
        <Route path=":id" element={<Questionnaire />} />
      </Route>
      <Route path="/*" element={<Navigate to={`/${search}`} />} />
    </Routes>
  );
};

export default App;
