import React, { SVGProps } from 'react';

const Like = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width="57" height="56" viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M53.0446 33.7773C54.4176 32.6829 55.4103 31.1659 55.8519 29.4365C56.5525 26.6928 55.6819 23.704 53.583 21.6254C53.0426 21.0903 52.3691 20.7093 51.6321 20.5218L36.9784 16.78L39.2628 9.36342C39.8118 7.57036 39.6102 5.68327 38.6991 4.05113C38.2527 3.24733 37.6462 2.54363 36.917 1.9835C36.1879 1.42338 35.3516 1.01872 34.46 0.794558C31.2823 -0.0168574 27.9252 1.40413 26.3002 4.2503L16.1981 21.9145L8.29686 19.8969C7.21525 19.6207 6.11827 20.2714 5.84207 21.353L0.162166 43.5963C-0.114027 44.6779 0.536677 45.7749 1.61829 46.0511L38.3625 55.4338C38.9247 55.5774 39.5027 55.6078 40.0661 55.5173C43.2916 55.0196 45.8932 52.6571 46.6984 49.504C46.895 48.734 46.9785 47.9482 46.9458 47.1587C48.3188 46.0643 49.3115 44.5473 49.7531 42.8179C49.9497 42.0479 50.0332 41.2621 50.0005 40.4726C51.3735 39.3782 52.3662 37.8612 52.8078 36.1318C52.9922 35.3587 53.0773 34.5668 53.0446 33.7773V33.7773ZM5.1861 42.2754L9.61768 24.9208L14.5674 26.1847L10.1358 43.5394L5.1861 42.2754ZM49.5835 30.6348L47.9487 31.4541L48.4018 33.2232C48.551 33.8059 48.5495 34.4171 48.3974 34.9991C48.1399 36.0074 47.455 36.8544 46.5287 37.3209L44.894 38.1402L45.347 39.9093C45.4963 40.492 45.4948 41.1032 45.3427 41.6852C45.0852 42.6934 44.4003 43.5405 43.474 44.007L41.8392 44.8263L42.2923 46.5953C42.4416 47.1781 42.4401 47.7892 42.288 48.3712C41.9384 49.7401 40.8166 50.7685 39.4264 51.0123L14.0483 44.5319L18.5283 26.9878L30.2338 6.51105C30.5348 5.98645 30.9985 5.57425 31.5548 5.3369C32.1111 5.09956 32.7296 5.05001 33.3166 5.19576C33.781 5.31435 34.205 5.56582 34.5014 5.93443C34.9909 6.54111 35.14 7.30822 34.9176 8.02604L31.2351 20.0001L50.4475 24.906C51.3651 25.8498 51.7502 27.1459 51.4521 28.313C51.1947 29.3213 50.5113 30.1622 49.5835 30.6348Z"
      fill="#0CC638"
    />
  </svg>
);

export default Like;
