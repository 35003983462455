export class ArrayHelper {
  static findNextItem = <T extends { id: number; isCompleted: boolean } = never>(
    array: T[],
    currentId: number
  ): T | null => {
    const currentIndex = array.findIndex((item) => item.id === currentId);
    if (currentIndex === -1) return null;

    const hasOneNext = array.some((item) => item.id !== currentId && !item.isCompleted);
    if (!hasOneNext) return null;

    let nextIndex = (currentIndex + 1) % array.length;
    while (array[nextIndex].isCompleted) nextIndex = (nextIndex + 1) % array.length;
    return array[nextIndex];
  };
}
