import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Questionnaire } from 'core/models/questionnaire.model';
import styles from './QuestionnaireCard.module.css';

type Props = {
  id: Questionnaire['id'];
  title: Questionnaire['title'];
  isCompleted: Questionnaire['isCompleted'];
};

const QuestionnaireCard: React.FC<Props> = ({ id, title, isCompleted }): JSX.Element | null => {
  const { search } = useLocation();
  return (
    <Link to={`/questionnaires/${id}${search}`} className={styles.root} data-is-completed={isCompleted}>
      {title}
    </Link>
  );
};

export default QuestionnaireCard;
