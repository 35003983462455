import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ScreenError from 'components/ScreenError';
import ScreenLoader from 'components/ScreenLoader';
import { CheckHelper } from 'core/helpers/check.helper';
import { PostMessageHelper } from 'core/helpers/post-message.helper';
import useQuery from 'core/hooks/use-query';
import { patchAppState } from 'core/store/app/app.slice';
import { QuestionnairesSelectors } from 'core/store/questionnaires/questionnaires.selectors';
import { QuestionnairesThunks } from 'core/store/questionnaires/questionnaires.thunks';
import { AppDispatch } from 'core/store/store';
import { UserSelectors } from 'core/store/user/user.selectors';
import { UserThunks } from 'core/store/user/user.thunks';

type Props = {
  children: React.ReactNode;
};

const Initializer: React.FC<Props> = ({ children }): JSX.Element | null => {
  const [error, setError] = useState<string>();
  const [ready, setReady] = useState<boolean>(false);

  const token = useQuery('token');
  const taskId = useQuery('taskId');
  const contextId = useQuery('contextId');
  const dispatch = useDispatch<AppDispatch>();
  const user = useSelector(UserSelectors.state);
  const questionnaires = useSelector(QuestionnairesSelectors.state);

  useEffect(() => {
    if (ready) return;

    if (!token) {
      setError('JWT Token is missing');
      return;
    }
    if (!taskId) {
      setError('Task ID is missing');
      return;
    }
    if (!contextId) {
      setError('Context ID is missing');
      return;
    }

    const onInit = async () => {
      try {
        await dispatch(patchAppState({ token, taskId, contextId }));
        await dispatch(UserThunks.postMe());
        await dispatch(QuestionnairesThunks.get());
      } catch (e: unknown) {
        const err = CheckHelper.hasErrorMessage<{ message: string }>(e) ? e.message : 'Unknown error';
        PostMessageHelper.error(err);
        setError(err);
      }
    };
    onInit().then(() => setReady(true));
  }, [taskId, contextId, token, ready, dispatch]);

  if (error) return <ScreenError message={error} />;

  if (user.error) return <ScreenError message={user.error} />;

  if (questionnaires.error) return <ScreenError message={questionnaires.error} />;

  if (!ready) return <ScreenLoader />;

  return <>{children}</>;
};

export default Initializer;
